@tailwind base;
@tailwind components;
@tailwind utilities;
.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.index-banner-bg {
    height: calc(53vh + 8vw);
}

.index-sm-bg {
    min-height: 100vh;
    background: url("./assets/index-banner.jpeg");
    background-size: cover;
    background-position: center;
}

.index-banner-bg-2 {
    height: calc(53vh + 8vw);
}

.index-sm-bg-2 {
    min-height: 100vh;
    background: url("./assets/index-banner2.jpeg");
    background-size: cover;
    background-position: center;
}

.product-banner-bg {
    height: calc(15vh + 20vw);
    background: url("./assets/about.jpeg");
    background-size: cover;
    background-position: center;
}

.about-banner-bg {
    height: calc(15vh + 20vw);
    background: url("./assets/research.jpeg");
    background-size: cover;
    background-position: center;
}

.about-banner-bg {
    height: calc(15vh + 20vw);
    background: url("./assets/location.jpeg");
    background-size: cover;
    background-position: center;
}

.page-title {
    background: url("./assets/dot.svg") top right no-repeat;
    padding-right: 50px;
    padding-top: 20px;
}

.index-banner-second-font-size {
    font-size: calc(9px + 2.08333vw);
}